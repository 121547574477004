/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import TimelineItem from "examples/Timeline/TimelineItem";

function OrdersOverview() {
  return (
    <Card sx={{ height: "100%" }}>
      <MDBox pt={3} px={3}>
        <MDTypography variant="h6" fontWeight="medium">
          Last Cooking Session
        </MDTypography>
        <MDBox mt={0} mb={2}>
          <MDTypography variant="button" color="text" fontWeight="regular">
            <MDTypography display="inline" variant="body2" verticalAlign="middle">
              <Icon sx={{ color: ({ palette: { success } }) => success.main }}>arrow_upward</Icon>
            </MDTypography>
            &nbsp;
            <MDTypography variant="button" color="text" fontWeight="medium">
              24%
            </MDTypography>{" "}
            increase in time for steak
          </MDTypography>
        </MDBox>
      </MDBox>
      <MDBox p={2}>
        <TimelineItem
          color="info"
          icon="play_arrow"
          title="Start cooking steak"
          dateTime="08 AUG 6:21 PM PST"
        />
        <TimelineItem
          color="warning"
          icon="local_fire_department"
          title="Cooking at 390F"
          dateTime="08 AUG 6:21 PM PST"
        />
        <TimelineItem
          color="error"
          icon="stop"
          title="User manually stopped cooking"
          dateTime="08 AUG 6:25 PM PST"
        />
        <TimelineItem
          color="warning"
          icon="local_fire_department"
          title="Restarted cooking session"
          dateTime="08 AUG 6:26 PM PST"
        />
        <TimelineItem
          color="primary"
          icon="access_time"
          title="Steak resting for 7 minutes"
          dateTime="08 AUG 6:32 PM PST"
        />
        <TimelineItem
          color="success"
          icon="done"
          title="Your medium-rare steak is ready!"
          dateTime="08 AUG 6:39 PM PST"
          lastItem
        />
      </MDBox>
    </Card>
  );
}

export default OrdersOverview;
