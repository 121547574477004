/* eslint-disable */

import * as React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import CircularProgress from "@mui/material/CircularProgress";
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";

import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import MDModal from "components/MDModal";

import DefaultProjectCard from "examples/Cards/ProjectCards/DefaultProjectCard";

import prototype from "assets/images/new-device.jpg";

const style = {
  display: "grid",
  gridTemplateColumns: "1fr",
  justifyItems: "center",
  alignItems: "center",
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "50%",
  height: "50%",
  bgcolor: "background.paper",
  border: "1px solid #B69552",
  boxShadow: 24,
  p: 4,
};

export default function BasicModal(props) {
  const addDevice = () => {
    props.handleClose();
    props.setAddDevice(false);
    props.setDevice(true);
  };

  console.log(props);

  return props.showCircle ? (
    <div>
      <Modal
        open={props.open}
        onClose={props.handleClose}
        aria-labelledby="modal-device-add"
        aria-describedby="modal-device-add-description"
      >
        <Box sx={style}>
          <CircularProgress size={window.innerHeight / 8} thickness={4} />
          <Typography variant="h6" component="h2">
            Fetching nearby devices...
          </Typography>
        </Box>
      </Modal>
    </div>
  ) : (
    <div>
      <Modal
        open={props.open}
        onClose={props.handleClose}
        aria-labelledby="modal-device-add"
        aria-describedby="modal-device-add-description"
      >
        <Box sx={style}>
          <Card>
            <MDBox pb={2} px={3}>
              <DefaultProjectCard
                image={prototype}
                label="Serial No. 986830"
                title="FRY-AI Model 1"
                icon={
                  <>
                    <Icon color="info">bluetooth_connected</Icon>{" "}
                    <Icon color="goldComplement">wifi</Icon> <Icon color="success">check</Icon>
                  </>
                }
                action={{}}
              />
            </MDBox>
          </Card>
          <MDButton variant="gradient" color="dark" onClick={addDevice}>
            add device
          </MDButton>
        </Box>
      </Modal>
    </div>
  );
}
