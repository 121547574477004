/* eslint react/prop-types: 0 */

/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState, useEffect } from "react";
import FadeIn from "react-fade-in";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDAvatar from "components/MDAvatar";
import MDBadge from "components/MDBadge";
import MDButton from "components/MDButton";
import MDModal2 from "components/MDModal2";
// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";
import TimelineItem from "examples/Timeline/TimelineItem";

// Images
import steak from "assets/images/table-steak-generic.jpg";
import fries from "assets/images/table-fry-generic.jpg";

function Tables() {
  const [cookingSessions, setCookingSessions] = useState([]);
  const [cookingSessionActive, setCookingSessionActive] = useState("");
  const [open, setOpen] = useState(false);

  const [createdTime, setCreatedTime] = useState(0);
  const [endTime, setEndTime] = useState(0);

  const Author = ({ image, name, email }) => (
    <MDBox display="flex" alignItems="center" lineHeight={1}>
      <MDAvatar src={image} name={name} size="sm" />
      <MDBox ml={2} lineHeight={1}>
        <MDTypography display="block" variant="button" fontWeight="medium">
          {name}
        </MDTypography>
        <MDTypography variant="caption">{email}</MDTypography>
      </MDBox>
    </MDBox>
  );

  const Job = ({ title, description }) => (
    <MDBox lineHeight={1} textAlign="left">
      <MDTypography display="block" variant="caption" color="text" fontWeight="medium">
        {title}
      </MDTypography>
      <MDTypography variant="caption">{description}</MDTypography>
    </MDBox>
  );

  async function fetchData() {
    try {
      const response = await fetch(
        "https://j4bxowdj9k.execute-api.us-east-1.amazonaws.com/dev/cook/getCookingSessionData/deank1",
        {
          method: "GET",
        }
      );
      console.log("fetching data");
      if (response.ok) {
        const res = await response.json();
        console.log(res.data);
        // Sort cooking sessions in chronological order
        res.data.sort((a, b) => {
          return new Date(b.created) - new Date(a.created);
        });
        // Take the latest cook and set the start and end times
        setCreatedTime(res.data[0].created);
        setEndTime(res.data[0].end_cook_time);
        setCookingSessions(res.data);
      }
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    fetchData();
    const interval = setInterval(() => fetchData(), 30000); //30 seconds
    return () => {
      clearInterval(interval);
    };
  }, []);

  const mapCookingSessionToTableRow = () => {
    const cookingSessionRows = [];
    if (cookingSessions && cookingSessions.length > 0) {
      // Map data in chronological order
      cookingSessions.map((session) => {
        cookingSessionRows.push({
          meal: (
            <Author
              image={session.food_type === "steak" ? steak : fries}
              name={session.food_type}
              email={session.username}
            />
          ),
          date: <Job title={new Date(session.created).toLocaleString()} />,
          status:
            session.end_cook_time > Date.now() ? (
              <MDBox ml={-1}>
                <MDBadge badgeContent="in progress" color="info" variant="gradient" size="sm" />
              </MDBox>
            ) : (
              <MDBox ml={-1}>
                <MDBadge badgeContent="done" color="success" variant="gradient" size="sm" />
              </MDBox>
            ),
          details: (
            <MDBox>
              <div>
                <MDTypography
                  component="a"
                  href="#"
                  variant="caption"
                  color="text"
                  fontWeight="medium"
                >
                  {session.cook_time} seconds
                </MDTypography>
              </div>
              <div>
                <MDTypography
                  component="a"
                  href="#"
                  variant="caption"
                  color="text"
                  fontWeight="medium"
                >
                  {session.temperature} degrees F
                </MDTypography>
              </div>
              <div>
                <MDTypography
                  component="a"
                  href="#"
                  variant="caption"
                  color="text"
                  fontWeight="medium"
                >
                  {session.weight} ounces
                </MDTypography>
              </div>
              <div>
                <MDTypography
                  component="a"
                  href="#"
                  variant="caption"
                  color="text"
                  fontWeight="medium"
                >
                  {session.thickness} inches
                </MDTypography>
              </div>
              <div>
                <MDTypography
                  component="a"
                  href="#"
                  variant="caption"
                  color="text"
                  fontWeight="medium"
                >
                  {session.length} inches
                </MDTypography>
              </div>
              <div>
                <MDTypography
                  component="a"
                  href="#"
                  variant="caption"
                  color="text"
                  fontWeight="medium"
                >
                  {session.area} inches squared
                </MDTypography>
              </div>
            </MDBox>
          ),
          action: session.feedback ? (
            <MDButton variant="text" color="gold">
              Feedback
            </MDButton>
          ) : (
            <MDButton variant="gradient" color="gold" onClick={handleOpen(session.id)}>
              Feedback
            </MDButton>
          ),
        });
      });
    }
    return {
      columns: [
        { Header: "Meal", accessor: "meal", width: "15%", align: "left" },
        { Header: "Date", accessor: "date", width: "10%", align: "left" },
        { Header: "Status", accessor: "status", width: "20%", align: "center" },
        { Header: "Details", accessor: "details", width: "20%", align: "center" },
        { Header: "Action", accessor: "action", width: "20%", align: "center" },
        { Header: "", accessor: "placeholder", width: "20%", align: "center" },
      ],

      rows: cookingSessionRows,
    };
  };

  const handleOpen = (id) => () => {
    setCookingSessionActive(id);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const { columns, rows } = mapCookingSessionToTableRow();

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="gold"
                borderRadius="lg"
                coloredShadow="gold"
              >
                <MDTypography variant="h6" color="white">
                  Current Cook
                </MDTypography>
              </MDBox>
              <MDBox py={2} px={60}>
                <FadeIn delay={3000} transitionDuration={1500}>
                  <TimelineItem
                    color="info"
                    icon="play_arrow"
                    title="Start cooking fries"
                    dateTime={new Date(createdTime).toLocaleString()}
                  />
                </FadeIn>
                <FadeIn delay={4000} transitionDuration={1500}>
                  <TimelineItem
                    color="warning"
                    icon="local_fire_department"
                    title="Cooking at 390F"
                    dateTime={new Date(createdTime).toLocaleString()}
                  />
                </FadeIn>
                <FadeIn delay={4000 + (endTime - createdTime)} transitionDuration={1500}>
                  <TimelineItem
                    color="success"
                    icon="done"
                    title="Your extra-crispy fries are ready!"
                    dateTime={new Date(endTime).toLocaleString()}
                    lastItem
                  />
                </FadeIn>
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <MDBox pt={6} pb={3}>
        {open ? (
          <MDModal2
            open={open}
            handleClose={handleClose}
            data={cookingSessions.filter((session) => session.id === cookingSessionActive)[0]}
            fetchCallback={() => fetchData()}
          ></MDModal2>
        ) : (
          <></>
        )}
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="gold"
                borderRadius="lg"
                coloredShadow="gold"
              >
                <MDTypography variant="h6" color="white">
                  Cooking Session History
                </MDTypography>
              </MDBox>
              <MDBox pt={3}>
                <DataTable
                  table={{ columns, rows }}
                  isSorted={false}
                  entriesPerPage={false}
                  showTotalEntries={false}
                  noEndBorder
                />
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
    </DashboardLayout>
  );
}

export default Tables;
