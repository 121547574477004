/* eslint-disable */
import * as React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";
import Slider from "@mui/material/Slider";

import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";

import DefaultProjectCard from "examples/Cards/ProjectCards/DefaultProjectCard";

import prototype from "assets/images/new-device.jpg";

const style = {
  display: "grid",
  gridTemplateColumns: "1fr",
  justifyItems: "center",
  alignItems: "center",
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "50%",
  height: "50%",
  bgcolor: "background.paper",
  border: "1px solid #B69552",
  boxShadow: 24,
  p: 4,
};

export default function BasicModal2(props) {
  const [satisfaction, setSatisfaction] = React.useState(3);
  const [foodspecificEvaluation, setFoodSpecificEvaluation] = React.useState(3);
  const [showSecondScreen, setShowSecondScreen] = React.useState(false);

  // TODO: figure out CORS error
  const submitFeedback = async (id) => {
    const data = {
      cookingSessionId: id,
      satisfaction: satisfaction,
      foodSpecificEvaluation: foodspecificEvaluation,
    };
    try {
      const response = await fetch(
        `https://j4bxowdj9k.execute-api.us-east-1.amazonaws.com/dev/feedback/updateCookingSession`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(data),
        }
      );
      console.log(response);
      // if (response.ok) {
      //   console.log(response);
      //   props.handleClose();
      // }
    } catch (error) {
      console.log(error);
    } finally {
      props.handleClose();
      props.fetchCallback();
      setShowSecondScreen(false);
    }
  };

  const displaySecondScreen = () => {
    setShowSecondScreen(true);
  };

  console.log(props.data);

  const marks = [
    {
      value: 1,
      label: "A lot less",
    },
    {
      value: 2,
      label: "Slightly less",
    },
    {
      value: 3,
      label: "Keep the same",
    },
    {
      value: 4,
      label: "Slightly more",
    },
    {
      value: 5,
      label: "A lot more",
    },
  ];

  const marksMobile = [
    {
      value: 1,
      label: "--",
    },
    {
      value: 2,
      label: "-",
    },
    {
      value: 3,
      label: ".",
    },
    {
      value: 4,
      label: "+",
    },
    {
      value: 5,
      label: "++",
    },
  ];

  function valuetext(value, label) {
    return `${value} + ${label}`;
  }

  return props.data.feedback ? (
    <div>
      <Modal
        open={props.open}
        onClose={props.handleClose}
        aria-labelledby="modal-device-add"
        aria-describedby="modal-device-add-description"
      >
        <Box sx={style}>
          <MDTypography variant="h6" component="h2">
            Thank you for your feedback
          </MDTypography>
        </Box>
      </Modal>
    </div>
  ) : (
    <div>
      <Modal
        open={props.open}
        onClose={props.handleClose}
        aria-labelledby="modal-device-add"
        aria-describedby="modal-device-add-description"
      >
        <Box sx={style}>
          {showSecondScreen ? (
            <>
              <Box>
                {props.data.food_type === "steak" ? (
                  <MDTypography variant="h6" component="h2">
                    Your steak was cooked to MEDIUM RARE. Do you want it more or less done next
                    time?
                  </MDTypography>
                ) : (
                  <MDTypography variant="h6" component="h2">
                    Your fries were cooked to MODERATELY CRISPY. Do you want them more or less
                    crispy next time?
                  </MDTypography>
                )}
                {window.innerWidth < 1500 ? (
                  <Slider
                    aria-label="FoodSpecificEvaluation"
                    defaultValue={3}
                    getAriaValueText={valuetext}
                    step={1}
                    valueLabelDisplay="auto"
                    marks={marksMobile}
                    min={1}
                    max={5}
                    onChange={(e, val) => setFoodSpecificEvaluation(val)}
                  />
                ) : (
                  <Slider
                    aria-label="FoodSpecificEvaluation"
                    defaultValue={3}
                    getAriaValueText={valuetext}
                    step={1}
                    valueLabelDisplay="auto"
                    marks={marks}
                    min={1}
                    max={5}
                    onChange={(e, val) => setFoodSpecificEvaluation(val)}
                  />
                )}
              </Box>
              <MDButton
                variant="gradient"
                color="dark"
                onClick={() => submitFeedback(props.data.id)}
              >
                Submit feedback
              </MDButton>
            </>
          ) : (
            <>
              <Box>
                <MDTypography variant="h6" component="h2">
                  How satisfied were you with your meal?
                </MDTypography>
                <Slider
                  aria-label="Satisfaction"
                  defaultValue={3}
                  valueLabelDisplay="auto"
                  step={1}
                  marks
                  min={1}
                  max={5}
                  onChange={(e, val) => setSatisfaction(val)}
                />
              </Box>
              <MDButton variant="gradient" color="dark" onClick={() => displaySecondScreen()}>
                <Icon sx={{ fontWeight: "bold" }} fontSize="small">
                  arrow_forward
                </Icon>
              </MDButton>
            </>
          )}
        </Box>
      </Modal>
    </div>
  );
}
