/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState } from "react";

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";
import Tooltip from "@mui/material/Tooltip";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import MDModal from "components/MDModal";

import Bill from "layouts/billing/components/Bill";
import DefaultProjectCard from "examples/Cards/ProjectCards/DefaultProjectCard";

// Images
import masterCardLogo from "assets/images/logos/mastercard.png";
import visaLogo from "assets/images/logos/visa.png";
import prototype from "assets/images/prototype-2.jpg";

// Material Dashboard 2 React context
import { useMaterialUIController } from "context";

function PaymentMethod() {
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [showCircle, setShowCircle] = useState(true);
  const [addDevice, setAddDevice] = useState(false);
  const [device, setDevice] = useState(true); // reset to false once implement actual device connection API

  // const createNewDevice = async () => {
  //   let model = "AF101";
  //   let serialNo = "986830";
  //   try {
  //     const data = { model: model, serialNo: serialNo };
  //     const response = await fetch("http://10.0.0.185:5000/connect", {
  //       method: "POST",
  //       headers: {
  //         "Content-Type": "application/json",
  //       },
  //       body: JSON.stringify(data),
  //     });
  //     const responseBody = await response.json();
  //     setDevice(true);
  //     console.log(responseBody);
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  const fetchDeviceConnection = async () => {
    try {
      const response = await fetch("http://10.0.0.176:5000/", {
        method: "GET",
      });
      if (response.ok) {
        setShowCircle(false);
        setAddDevice(true);
        return true;
      }
    } catch (error) {
      console.log(error);
      return false;
    }
  };

  function tryN(callback, delay, tries) {
    if (tries && callback() !== true) {
      setTimeout(tryN.bind(this, callback, delay, tries - 1), delay);
    }
  }

  tryN(fetchDeviceConnection, 5000, 10);

  return (
    <Card id="delete-account">
      {open ? (
        <MDModal
          open={open}
          handleClose={handleClose}
          showCircle={showCircle}
          setAddDevice={setAddDevice}
          setDevice={setDevice}
        ></MDModal>
      ) : (
        <></>
      )}
      <MDBox pt={2} px={2} display="flex" justifyContent="space-between" alignItems="center">
        <MDTypography variant="h6" fontWeight="medium">
          Your Devices
        </MDTypography>
        {device ? (
          <></>
        ) : (
          <MDButton variant="gradient" color="dark" onClick={handleOpen}>
            <Icon sx={{ fontWeight: "bold" }}>add</Icon>
            &nbsp;add new device
          </MDButton>
        )}
      </MDBox>
      <Card id="delete-account">
        <MDBox pt={1} pb={2} px={2}>
          <MDBox component="ul" display="flex" flexDirection="column" p={0} m={0}>
            <MDBox p={2}>
              <Grid container spacing={6}>
                <Grid item xs={12} md={6} xl={3}>
                  {device ? (
                    <Card>
                      <MDBox pb={2} px={3}>
                        <DefaultProjectCard
                          image={prototype}
                          label="Serial No. 986830"
                          title="FRY-AI Model 1"
                          icon={
                            <>
                              <Icon color="info">bluetooth_connected</Icon>{" "}
                              <Icon color="goldComplement">wifi</Icon>{" "}
                              <Icon color="success">check</Icon>
                            </>
                          }
                          action={{
                            type: "internal",
                            route: "/devices",
                            color: "goldComplement",
                            label: "view device details",
                          }}
                        />
                      </MDBox>
                    </Card>
                  ) : (
                    <></>
                  )}
                </Grid>
              </Grid>
            </MDBox>
          </MDBox>
        </MDBox>
      </Card>
    </Card>
  );
}

export default PaymentMethod;
