/* eslint-disable react/prop-types */
/* eslint-disable react/function-component-definition */
/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Tooltip from "@mui/material/Tooltip";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDAvatar from "components/MDAvatar";
import MDProgress from "components/MDProgress";

// Images
import logoSteak from "assets/images/table-steak-generic.jpg";
import logoFries from "assets/images/table-fry-generic.jpg";
import logoCookies from "assets/images/cookies.jpg";
import logoPaneer from "assets/images/paneer-tikka.jpg";
import dean from "assets/images/dean.jpg";
import max from "assets/images/max.jpg";
import genericUser from "assets/images/generic-user-icon.jpg";

export default function data() {
  const avatars = (users) =>
    users.map(([image, name]) => (
      <Tooltip key={name} title={name} placeholder="bottom">
        <MDAvatar
          src={image}
          alt="name"
          size="xs"
          sx={{
            border: ({ borders: { borderWidth }, palette: { white } }) =>
              `${borderWidth[2]} solid ${white.main}`,
            cursor: "pointer",
            position: "relative",

            "&:not(:first-of-type)": {
              ml: -1.25,
            },

            "&:hover, &:focus": {
              zIndex: "10",
            },
          }}
        />
      </Tooltip>
    ));

  const Company = ({ image, name }) => (
    <MDBox display="flex" alignItems="center" lineHeight={1}>
      <MDAvatar src={image} name={name} size="sm" />
      <MDTypography variant="button" fontWeight="medium" ml={1} lineHeight={1}>
        {name}
      </MDTypography>
    </MDBox>
  );

  return {
    columns: [
      { Header: "food", accessor: "food", width: "45%", align: "left" },
      { Header: "users", accessor: "users", width: "10%", align: "left" },
      { Header: "number of times cooked", accessor: "counter", align: "center" },
      { Header: "average satisfaction", accessor: "satisfaction", align: "center" },
    ],

    rows: [
      {
        food: <Company image={logoSteak} name="Ribeye Steak" />,
        users: (
          <MDBox display="flex" py={1}>
            {avatars([
              [dean, "Dean"],
              [max, "Max"],
              [genericUser, "Unknown User"],
              [genericUser, "Unknown User"],
            ])}
          </MDBox>
        ),
        counter: (
          <MDTypography variant="caption" color="text" fontWeight="medium">
            6
          </MDTypography>
        ),
        satisfaction: (
          <MDBox width="8rem" textAlign="left">
            <MDProgress value={90} color="success" variant="gradient" label={false} />
          </MDBox>
        ),
      },
      {
        food: <Company image={logoFries} name="Regular Fries" />,
        users: (
          <MDBox display="flex" py={1}>
            {avatars([
              [dean, "Dean"],
              [max, "Max"],
              [genericUser, "Unknown User"],
            ])}
          </MDBox>
        ),
        counter: (
          <MDTypography variant="caption" color="text" fontWeight="medium">
            5
          </MDTypography>
        ),
        satisfaction: (
          <MDBox width="8rem" textAlign="left">
            <MDProgress value={80} color="info" variant="gradient" label={false} />
          </MDBox>
        ),
      },
      {
        food: <Company image={logoCookies} name="Chocolate Chip Cookies" />,
        users: (
          <MDBox display="flex" py={1}>
            {avatars([[dean, "Dean"]])}
          </MDBox>
        ),
        counter: (
          <MDTypography variant="caption" color="text" fontWeight="medium">
            3
          </MDTypography>
        ),
        satisfaction: (
          <MDBox width="8rem" textAlign="left">
            <MDProgress value={50} color="warning" variant="gradient" label={false} />
          </MDBox>
        ),
      },
      {
        food: <Company image={logoPaneer} name="Paneer Tikka" />,
        users: (
          <MDBox display="flex" py={1}>
            {avatars([[max, "Max"]])}
          </MDBox>
        ),
        counter: (
          <MDTypography variant="caption" color="text" fontWeight="medium">
            1
          </MDTypography>
        ),
        satisfaction: (
          <MDBox width="8rem" textAlign="left">
            <MDProgress value={10} color="error" variant="gradient" label={false} />
          </MDBox>
        ),
      },
    ],
  };
}
